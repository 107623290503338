thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.MainRowsec {
  padding: 2%;
}

.view-btn_holder {
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
}

.MainRowsec .add-sub-txttt {
  font-size: 14px;
  margin-bottom: 0;
}

.tab-radio .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: none;
}

.tab-radio .btn-primary {
  height: 38px;
}

.tab-radio .btn-danger {
  height: 38px;
}

.tab-radio .breadcrumb a {
  border-bottom: none;
  color: none !important;
  text-decoration: none !important;
}

.tab-radio .btn-add {
  font-size: 14px;
  width: 100%;
  color: #fff;
  background: #5468b0;
  border-radius: 5px;
}



.tab-radio .btn-columns {
  color: #fff;
  background: #5468b0;
  font-size: 14px;
  border-radius: 5px;

}

.tab-radio .dropdown-item {
  font-size: 14px;
}

.tab-radio .dropdown-toggle::after {
  display: none;
}

.tab-radio .btn-download {
  background: #e89300 0% 0% no-repeat padding-box;
}

.tab-radio .btn-download:hover {
  background: #e89300 0% 0% no-repeat padding-box;
}

.tab-radio .add .columns {
  width: 13px;
  height: 16px;
}

.tab-radio .add .plus {
  width: 17px;
  height: 17px;
}

.tab-radio .border-line {
  border-bottom: 1px solid #707070;
}

.tab-radio .breadcrumb .breadcrumb-item a {
  color: none !important;
  text-decoration: none !important;
}

.tab-radio .table .circle {
  border-radius: 100px;
  height: 9px;
  width: 9px;
  background: #019e11;
}

.tab-radio .table .circle1 {
  border-radius: 100px;
  height: 9px;
  width: 9px;
  background: red;
}

.tab-radio td {
  font-weight: 500;
  color: #292929;
  font-size: 13px;
}

.tab-radio table.table.table-bordered.tableborder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
}

.tab-radio p.show {
  font-size: 12px;
  color: black;
  font-weight: 700;
}

.AdvanceDashboard .talentCategory_Table {
  /* margin-left: 3%; */
}

.tab-radio .form-check-input:checked:focus {
  box-shadow: none;
}

.tab-radio select.form-select.form-select-sm:focus {
  box-shadow: none;
}

.tab-radio .num .form-control:focus {
  box-shadow: none;
}

.tab-radio .data .table th.tax-name {
  width: 30%;
  color: #292929;
  font-size: 13px;
  text-wrap: nowrap;
}

.tab-radio .data .table td.tax-rate {
  text-align: center;
}

.tab-radio .data .table th {
  font-size: 13px;
  color: #292929;
}

.tab-radio .data .table th.rate {
  font-size: 13px;
  color: #292929;
  text-align: center;
}

.tab-radio .data .table th.status {
  font-size: 13px;
  color: #292929;
}

.tab-radio .data .table th.active {
  font-size: 13px;
  color: #292929;
}

li.breadcrumb-item {
  color: #555555;
  font-size: 13px;
}

.tab-radio {
  /* margin-right: 3%; */
  padding: 15px;
  background: #fff;
  box-shadow: 0 0 4px #000;
}

/* .tab-radio .round-check .form-check-input:checked {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  width: 18px;
  height: 18px;
}

.tab-radio .round-check .form-check-input:checked[type=checkbox] {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  width: 18px;
  height: 18px;
  background-image: url('../../assets/icons/check.png');
}

.tab-radio .round-check .form-check-input[type=checkbox] {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  width: 18px;
  height: 18px;
}

.tab-radio .round-check .form-check-input[type=checkbox]:focus {
  box-shadow: none;
}


*/

.tab-radio .btn-sea {
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
}

/* .tab-radio select.form-select.form-select-sm {
  border: 1px solid #707070;
  height: 27px;
} 

.tab-radio button.btn.btn-search {
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  height: 27px;
  width: 222%;
}

.tab-radio .num .form-control {
  border: 1px solid #707070;
  height: 28px !important;
  width: 64% !important;
}

.Search {
  position: relative;
  left: -10%;
}

.tab-radio .search {
  left: 20px;
  position: absolute;
  top: 5px;
}


*/

.tab-radio .btn-reset {
  background-color: red;
  color: #fff;
  font-size: 14px;
  /* height: 27px; */

  text-align: center;
}

.tab-radio .btn-reset:hover {
  background-color: red;
  color: #fff;
  font-size: 14px;
  /* height: 27px; */

  text-align: center;
}

.tab-radio select.form-select.form-select-sm {
  border: 1px solid #707070;
  /* height: 27px; */
}



.tab-radio .btn-search:hover {
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  /* height: 27px; */
  width: 64px;
}

.tab-radio .num .form-control {
  /* border: 1px solid #707070; */
  /* height: 28px !important; */
  /* width: 64% !important; */
  font-size: 12px;
  height: 38px;
}

/* Media Query */

@media (min-width: 0px) and (max-width: 320px) {
  .tab-radio {
    margin-left: 5%;
  }

  section.Master-Manegement {
    height: auto;
  }

  .tab-radio button.btn.btn-add {
    width: 64%;
  }

  .tab-radio button.btn.btn-columns {
    /* width: 64%; */
    margin-left: 0px;
    margin-top: 3%;
    font-size: 12px;
  }

  .tab-radio .add {
    width: auto;
  }

  .tab-radio .add .columns {
    left: 5px;
  }

  .tab-radio .add .plus {
    left: 23px;
  }
    .MainRowsec .add-sub-txttt {
      font-size: 12px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
  .tab-radio {
      margin-left: 5%;
    }
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  section.Master-Manegement {
    height: auto;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
    font-size: 12px;
  }

  .tab-radio button.btn.btn-columns {
    /* width: 100%; */
    margin-left: 0px;
    font-size: 12px;
    margin-top: 3%;
  }
     .MainRowsec .add-sub-txttt {
       font-size: 12px;
     }

  /* .tab-radio .add .plus {
  left: 14px;
  
}
.tab-radio .add .columns {
 left: 5px;
} */
}

@media (min-width: 486px) and (max-width: 576px) {
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  .tab-radio button.btn.btn-columns {
    /* width: 100%; */
    margin-left: 0px;
    font-size: 13px;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
    font-size: 13px;
  }

  .tab-radio .add .columns {
    left: 7px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  .tab-radio button.btn.btn-columns {
    /* width: 100%; */
    margin-left: 0px;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
  }

  .tab-radio .add .columns {
    left: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  button.btn.btn-danger {
    /* margin-top: 8%; */
  }

  .tab-radio .btn-add {
    font-size: 12px;
  }

  .tab-radio .btn-columns {
    font-size: 12px;
  }

  .css-13cymwt-control {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .tab-radio button.btn.btn-primary {
    width: auto;
  }

  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  .tab-radio .btn-add {
    font-size: 12px;
  }

  .tab-radio .btn-columns {
    font-size: 12px;
  }

  .tab-radio button.btn.btn-columns {
    /* width: 100%; */
    margin-left: 0px;
  }

  .tab-radio button.btn.btn-add {
    width: 100%;
  }

  .tab-radio .add .columns {
    left: 7px;
  }

  .css-13cymwt-control {
    font-size: 12px;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  button.btn.btn-danger {
    /* margin-top: 6%; */
  }

  .css-13cymwt-control {
    font-size: 12px;
  }
}

.main-section .dropdown-toggle::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.main-section ul.dropdown-menu.show {
  background: #f3feff 0% 0% no-repeat padding-box;
}

.main-section .dropdown ul.dropdown-menu.show:focus {
  box-shadow: none;
}

.main-section .tab-radio .data .table th.tax1 {
  width: 24%;
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.sub1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.include1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.rate1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.status1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table th.active1 {
  font-size: 13px;
  color: #292929;
}

.main-section .tab-radio .data .table td.ten {
  text-align: center;
}

/* .main-section .tab-radio .add .dropdown .dropdown-menu  img.eye1 {
  position: absolute;
  top: 11px;
  right: 13px;
  height: 11px;
} */
.main-section .tab-radio .add .dropdown .dropdown-menu img.eye1 {
  position: absolute;
  right: 13px;
  width: 11%;
}

.main-section .col-form-label {
  font-weight: 500;
  font-size: 13px;
}

.main-section select.form-select {
  height: 28px;
  width: 100%;
}

.main-section .District p.district {
  font-weight: 500;
  font-size: 13px;
  color: #292929;
}

.main-section .District select.form-select {
  font-weight: 500;
  font-size: 12px;
}

.main-section .view-details .btn-view {
  color: white;
  font-size: 14px;
  width: 100%;
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.main-section .view-details .eye-img {
  width: 21px;
  height: 16px;
}

.main-section .view-details {
  margin-left: auto;
}

/*********modal start******/
.upload-modal .modal-content {
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 201px;
}

.upload-modal .modal-content .circle .circle-img {
  width: 52px;
  height: 52px;
}

.upload-modal .modal-content .add-success {
  color: #efefef;
  font-size: 25px;
}

.upload-modal .modal-content .modal-body p {
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.upload-modal .btn-yes {
  background-color: #efefef;
  color: red;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}

.upload-modal .btn-yes:hover {
  background-color: #efefef;
  color: red;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}

.upload-modal .btn-no {
  background-color: #efefef;
  color: #000;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}

.upload-modal .btn-no:hover {
  background-color: #efefef;
  color: #000;
  font-size: 14px;
  width: 25%;
  font-weight: 700;
}

.save-modal .modal .modal-content {
  /* height: 201px; */
  background: #07555f 0% 0% no-repeat padding-box;
  border-radius: 10px;
  z-index: 99999;
}

.save-modal .modal .modal-body .circle .circle-img {
  margin-top: 2%;
  margin-left: 37%;
  width: 114px;
  height: 114px;
}

.save-modal .modal .modal-body .add-success {
  color: white;
  font-size: 25px;
}

.form-label {
  font-weight: 600;
}

.jodit-react-container {
  width: 100% !important;
}